import { SignIn } from "@aws-amplify/ui-react/dist/types/components/Authenticator/SignIn";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import GumroadUpgradeButton from "../components/GumroadUpgradeButton";
import HamburgerMenu from "../components/HamburgerMenu";
import PasswordReset from "../components/PasswordReset";
import SignInButton from "../components/SignInButton";
import SignUpButton from "../components/SignUpButton";
import { getHasSubscription, getSubscription, isStripe } from "../utils/premium";
import { fetchFromApi } from "../utils/api";
import CooldownPopup from "../components/CooldownPopup";
import UpgradeOrgSubscriptionPopup from "../components/UpgradeOrgSubscriptionPopup";
import { AccountData } from "./Account"; // Import AccountData interface
import OrgPricingInfoPopup from "../components/OrgPricingInfoPopup";


const Layout = () => {
  const { width, height } = useWindowSize();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState<string | undefined>(undefined);
  const [showCooldownPopup, setShowCooldownPopup] = useState(false);
  const [accountData, setAccountData] = useState<AccountData | null>(null);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showPricingPopup, setShowPricingPopup] = useState(false);

  useEffect(() => {
    checkAuthStatus();
    if (!localStorage.getItem('cs2lens-deviceid')) {
      const newDeviceId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('cs2lens-deviceid', newDeviceId);
    }
    checkCooldown();
    fetchAccountData(); // Add this line to fetch account data
  }, []);

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  interface CooldownAPIResponse {
    cooldown: boolean;
  }
  const checkCooldown = async () => {
    fetchFromApi(`/cooldown?device_id=${localStorage.getItem('cs2lens-deviceid')}`)
      .then((response) => response.json())
      .then((data: CooldownAPIResponse) => {
        setShowCooldownPopup(data.cooldown);
      });
  };

  const fetchAccountData = async () => {
    try {
      const response = await fetchFromApi("/account");
      const data: AccountData = await response.json();
      setAccountData(data);
      if (data.org_sub_status === "CANCELLED") {
        setShowUpgradePopup(true);
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  const onForgotPassword = () => {
    setShowPasswordReset(true);
  };

  const socialMediaLinks = () => {
    return <span><span className="twitter-button">
      {width > 800 ?
        <span className="feedback-text-wrapper">
          <span className="feedback-text">We would <img className="heart-image" width={18} height={18} src={`${process.env.PUBLIC_URL}/heart.png`} alt="love" /> to hear from you!</span>
          <span className="feedback-text-arrow"><i className="bi bi-arrow-right"></i></span>
        </span>
        : null}
      <a href="https://twitter.com/cs2lens?ref_src=twsrc%5Etfw" target="_blank" className="twitter-button" data-show-count="false" rel="noreferrer">
        <svg width={20} height={20} viewBox='0 0 72 72'><path fill='rgb(255,255,255)' d='M68.812 15.14a26.189 26.189 0 0 1-7.52 2.06 13.125 13.125 0 0 0 5.757-7.243 26.133 26.133 0 0 1-8.314 3.176A13.066 13.066 0 0 0 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396a13.057 13.057 0 0 0-1.77 6.58c0 4.543 2.312 8.552 5.824 10.9a13.05 13.05 0 0 1-5.93-1.64c-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09a26.29 26.29 0 0 1-16.26 5.605c-1.055 0-2.096-.06-3.122-.184a37.036 37.036 0 0 0 20.067 5.882c24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693a26.61 26.61 0 0 0 6.532-6.774z' /></svg>
        <span className="feedback-label">Follow</span>
      </a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    </span>
      <span className="discord-button" style={{ marginLeft: '10px' }}>
        <a href="https://discord.gg/FdxpTYwtrV" target="_blank" className="discord-button" rel="noreferrer">
          <img src="/discord-icon.png" height={15}></img>
          <span className="feedback-label">Discord</span>
        </a>
      </span>
    </span>;
  }

  const pricing2025 = () => {
    return width > 800 && location.pathname === '/' && (
      <span className="feedback-text-wrapper">
        <span className="feedback-text" style={{ position: 'relative', left: '-90px' }}>
          <i className="bi bi-exclamation-diamond" style={{ marginRight: '10px', color: 'yellow' }} />
          Important: Your subscription pricing is changing in 2025
          <a href="#" onClick={(e) => { e.preventDefault(); setShowPricingPopup(true); }} style={{ marginLeft: '10px', color: '#888', fontSize: '0.9em' }}>
            Read more
          </a>
        </span>
      </span>
    );
  }

  const cs2Warning = () => {
    return width > 800 &&
      <span className="feedback-text-wrapper">
        <span className="feedback-text" style={{ position: 'relative', left: '-90px' }}><i className="bi bi-exclamation-diamond" style={{ marginRight: '10px', color: 'yellow' }} />Experiencing demo issues after the latest CS2 update. Thanks for your patience!</span>
      </span>
  }

  const cs2WarningOver = () => {
    return width > 800 &&
      <span className="feedback-text-wrapper">
        <span className="feedback-text" style={{ position: 'relative', left: '-90px', color: '#aaa' }}><i className="bi bi-check-circle-fill" style={{ marginRight: '10px', color: '#00aa00' }} />2024-05-31 - Update complete: Demos are back in business!</span>
      </span>
  }

  const betaHeader = () => {
    if (isAuthenticated === undefined) return null;
    return <div>
      {isAuthenticated ? (
        <HamburgerMenu />
      ) : (
        <>
          <span style={{ margin: '5px' }}>
            <SignUpButton onSignUp={() => { window.location.reload() }} />
          </span>
          <span style={{ margin: '5px' }}>
            <SignInButton onLogin={() => { window.location.reload() }} onForgotPassword={onForgotPassword} />
          </span>
          <PasswordReset show={showPasswordReset} onClose={() => {
            setShowPasswordReset(false);
            window.location.reload();
          }} />
        </>
      )}
    </div>
  }

  const coolDownPopup = showCooldownPopup && <CooldownPopup />;
  const upgradePopup = showUpgradePopup && <UpgradeOrgSubscriptionPopup org_name={accountData?.org_name || ''} org_email={accountData?.org_sub_email || ''} user_email={accountData?.email || ''} />;

  const location = useLocation();

  return (
    <>
      <nav className="navbar-dark csgolens-header">
        <div className="d-flex align-items-center" style={{ height: "100%" }}>
          <div className="flex-grow-1 navbar-margin">
            <a href={"/"} style={{ textDecoration: "none" }}>
              <img className="logo-icon" src="/promo/cs2lens-logo-v2-icon-dark-transparent-128.png" style={{ width: 20, height: 20, marginRight: '3px' }} />
              <span className="logo logo-csgo">CS2</span>
              <span className="logo logo-lens">LENS</span>
            </a>
            {width > 800 && hasSubscription &&
              <span className="logo" style={{ marginLeft: 10, top: '1px', color: subscription === 'PREMIUM' ? "#a80" : "#482", fontSize: '12px', fontFamily: 'arial' }}>{subscription}</span>
            }
            {isAuthenticated !== undefined && !hasSubscription &&
              <a href={"/about"} style={{ textDecoration: "none", color: "#777", fontSize: '12px' }}>
                <span style={{ marginLeft: 10, position: 'relative', top: 1 }}>About</span>
              </a>
            }
          </div>
          {width > 800 && !hasSubscription &&
            <div className="flex">
              {subscription === "FREE" && isAuthenticated !== undefined && location.pathname === '/' && socialMediaLinks()}
            </div>
          }
          {width > 800 && hasSubscription && accountData && accountData.org_name && accountData.org_name.length > 1 && pricing2025()}
          {/* {width > 800 && hasSubscription && location.pathname === '/' &&
          <div className="flex">
            {cs2WarningOver()}
          </div>
          } */}
          <div className="flex-grow-1">
            {/* {width > 1200 && isAuthenticated && isStripeCustomer &&
              <span style={{marginRight: '150px'}}><span style={{ marginRight: '5px' }}>⚠️</span> Immediate action is needed to restore your temporarily disabled PREMIUM subscription<span style={{ marginLeft: '12px' }}><GumroadUpgradeButton /></span></span>
            } */}
          </div>
          {betaHeader()}
        </div>
        {coolDownPopup}
        {upgradePopup}
        {<OrgPricingInfoPopup onClose={() => setShowPricingPopup(false)} show={showPricingPopup} />}
      </nav>
      <Outlet />
    </>
  );
};

export default Layout;