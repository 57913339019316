import { Button, Dropdown, Form, FormControl, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowSize from "../useWindowSize";
import MapIcon from '../components/MapIcon';
import TeamIcon from '../components/TeamIcon';
import SourceIcon from '../components/SourceIcon';
import TeamPicker from '../components/TeamPicker';
import { fetchFromApi } from '../utils/api';
import { Auth } from "aws-amplify";
import { ExternalStatsButton } from '../components/ExternalStatsButton';
import DeleteMatchButton from '../components/DeleteMatchButton';
import styles from './Matches.module.css';
import { fetchRoundsOfMatchIds, getPlaylistFromRounds } from '../utils/playlist';
import { getHasSubscription, getSubscription, isLocked } from '../utils/premium';
import EventPicker from '../components/EventPicker';

export interface ApiMatchesData {
  [matchID: string]: MatchData
}

export interface MatchData {
  match_id: string
  teamA_key: string,
  teamB_key: string,
  teamA_name: string,
  teamB_name: string,
  teamA_flag: string,
  teamB_flag: string,
  date: string,
  time: string,
  maps: string[]
  pointsA: number,
  pointsB: number,
  timestamp: number,
  stars: number,
  url: string,
  cs_version?: string | undefined;
}

export interface TeamData {
  name: string,
  flag: string,
}

export interface TeamsData {
  [teamID: string]: TeamData
}

export interface EventData {
  name: string,
  timestamp: number,
}

export interface EventsData {
  [eventKey: string]: EventData
}

function cleanMapName(name: string): string {
  return capitalizeFirstLetter(name.replace("de_", " "));
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export default function Matches() {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const [matches, setMatches] = useState<MatchData[]>([]);
  const [events, setEvents] = useState<EventsData>({});
  const [startDate, setStartDate] = useState<Date>(sessionStorage.getItem('match-filter-date') ? new Date(sessionStorage.getItem('match-filter-date')!) : new Date(Date.now()));
  const [selectedTeam, setSelectedTeam] = useState<string>(sessionStorage.getItem('match-filter-team') ? sessionStorage.getItem('match-filter-team') as string : 'Any');
  const [selectedMap, setSelectedMap] = useState<string>(sessionStorage.getItem('match-filter-map') ? sessionStorage.getItem('match-filter-map') as string : 'Any');
  const [selectedEvent, setSelectedEvent] = useState<string>(sessionStorage.getItem('match-filter-event') ? sessionStorage.getItem('match-filter-event') as string : 'Any');
  const [maxResults, setMaxResults] = useState<number>(50);
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<string>('');
  const [searchText, setSearchText] = useState("");
  const [filteredEvents, setFilteredEvents] = useState<EventsData | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");
  const [selected, setSelected] = useState<Set<string>>(new Set<string>());
  const [loadingAnalyseID, setLoadingAnalyseID] = useState<string | undefined>(undefined);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  useEffect(() => {
    var formattedDate = startDate.getFullYear() + "-" + (startDate.getMonth() + 1).toString().padStart(2, '0') + "-" + startDate.getDate().toString().padStart(2, '0');
    var url = `/matches?date=${formattedDate}`

    if (selectedTeam !== 'Any') {
      url += `&teams=${selectedTeam}`
    }

    if (selectedMap !== 'Any') {
      url += `&maps=${selectedMap}`
    }

    if (selectedEvent !== 'Any') {
      if (selectedEvent === 'esea' || selectedEvent === 'hltv') {
        url += `&sources=${selectedEvent}`
      } else {
        url += `&events=${selectedEvent}`
      }
    }

    setFetching(true);
    fetchFromApi(url)
      .then((response) => response.json())
      .then((data: ApiMatchesData) => {
        const sortedMatchesArray = Object.keys(data)
          .map((key) => { data[key].match_id = key; return data[key]; })
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            if (a.stars < b.stars) return 1;
            if (a.stars > b.stars) return -1;

            const aContainsEseaOrUpload = a.match_id.includes('esea-league') || a.match_id.includes('user-upload');
            const bContainsEseaOrUpload = b.match_id.includes('esea-league') || b.match_id.includes('user-upload');
            if (aContainsEseaOrUpload && !bContainsEseaOrUpload) return 1;
            if (!aContainsEseaOrUpload && bContainsEseaOrUpload) return -1;

            return b.timestamp - a.timestamp;
          });
        setMatches(sortedMatchesArray);
        setFetching(false);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [startDate, selectedTeam, selectedMap, selectedEvent]);

  useEffect(() => {
    const url = `/events`;
    fetchFromApi(url)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  function onDateChanged(date: Date) {
    if (date) {
      handleFilterChange(selectedTeam, selectedMap, selectedEvent, date)
    }
  }

  const handleAnalyseClick = async (button: number, match_id: string, teamKey: string, mapName: string) => {
    setLoadingAnalyseID(`${match_id}.${teamKey}.${mapName}`);
    const rounds = await fetchRoundsOfMatchIds([match_id], mapName);
    setLoadingAnalyseID(undefined);
    const playlist = getPlaylistFromRounds(rounds);
    playlist.team_key = teamKey;
    playlist.mapname = mapName;
    if (button === 0) {
      sessionStorage.setItem('playlist-analyse', JSON.stringify(playlist));
      navigate(`/analyse`);
    } else if (button === 1) {
      localStorage.setItem('playlist-analyse', JSON.stringify(playlist));
      window.open(`/analyse`, '_blank', 'noopener,noreferrer');
      window.focus();
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement, MouseEvent>, match_id: string, teamKey: string, mapName: string) => {
    if (event.button === 1) {
      // Prevent the default action for middle-click to open the new tab in the background
      setActiveDropdown('');
      event.preventDefault();
    }
    handleAnalyseClick(event.button, match_id, teamKey, mapName);
  };

  const handleFilterChange = (team: string, map: string, event: string, date: Date) => {
    setSelectedTeam(team);
    setSelectedMap(map);
    setStartDate(date);
    setSelectedEvent(event);
    sessionStorage.setItem('match-filter-team', team);
    sessionStorage.setItem('match-filter-map', map);
    sessionStorage.setItem('match-filter-event', event);
    sessionStorage.setItem('match-filter-date', date.toISOString());
  }

  const handleDropdownToggle = (dropdown: string) => {
    setActiveDropdown((old) => old === dropdown ? '' : dropdown);
    setSearchText("");
  };

  const handleEventSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setFilteredEvents(
      Object.keys(events)
        .filter(eventName => eventName.toLowerCase().includes(event.target.value.toLowerCase()))
        .reduce((filtered, eventKey) => {
          filtered[eventKey] = events[eventKey];
          return filtered;
        }, {} as EventsData)
    );
  };

  const handleSelectRow = (id: string, checked: boolean) => {
    if (checked) {
      setSelected((old) => {
        const newSelected = new Set(old);
        newSelected.add(id);
        return newSelected;
      });
    } else {
      setSelected((old) => {
        const newSelected = new Set(old);
        newSelected.delete(id);
        return newSelected;
      });
    }
  };

  const wideModeWidth = 900;
  const buttonClassName = width > wideModeWidth ? 'matches-filter-button' : 'matches-filter-button-sm';
  const containerClassName = width > wideModeWidth ? 'px-1' : 'matches-filter-container-sm';
  const colClassName = width > wideModeWidth ? 'col' : 'col matches-filter-col-sm';

  return (
    <div className='container match-table-container'>
      <div className={`${containerClassName}`}>
        <div className="row row-cols-auto">
          <div className={colClassName}>
            <TeamPicker
              isOpen={activeDropdown === 'teams'}
              selectedTeam={selectedTeam}
              wideMode={width > wideModeWidth}
              canSelectAny={true}
              label="Teams"
              btnClassName='matches-filter-button-sm'
              btnClassNameWide='matches-filter-button'
              onToggle={() => handleDropdownToggle('teams')}
              onSelect={(teamName: string) => handleFilterChange(teamName, selectedMap, selectedEvent, startDate)}
            />
          </div>
          <div className={colClassName}>
            <Dropdown
              onSelect={(eventKey: any, event: Object) => handleFilterChange(selectedTeam, eventKey, selectedEvent, startDate)}
              onToggle={() => handleDropdownToggle('maps')}
              show={activeDropdown === 'maps'}
              className="maps-dropdown"
              key="maps-dropdown"
            >
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={buttonClassName} size="sm">
                {selectedMap !== 'Any' ?
                  <><MapIcon name={selectedMap} size={14} />{selectedMap}</> :
                  <>Maps</>
                }
              </Dropdown.Toggle>
              <Dropdown.Menu className='maps-dropdown-menu'>
                <Dropdown.Item key={`map_Any`} eventKey={"Any"}>Any</Dropdown.Item>
                <Dropdown.Divider />
                {['ancient', 'anubis', 'dust2', 'inferno', 'mirage', 'nuke', 'overpass', 'train', 'vertigo'].map((mapName) => (
                  <Dropdown.Item key={`map_${mapName}`} eventKey={mapName}><MapIcon name={mapName} size={14} />{mapName}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={colClassName}>
            <EventPicker
              isOpen={activeDropdown === 'events'}
              selectedEvent={selectedEvent}
              wideMode={width > wideModeWidth}
              events={events}
              isAuthenticated={isAuthenticated || false}
              onToggle={() => handleDropdownToggle('events')}
              onSelect={(eventKey: string) => handleFilterChange(selectedTeam, selectedMap, eventKey, startDate)}
            />
          </div>
          {width > wideModeWidth ?
            <div className={colClassName}>
              <DatePicker
                className={`match-datepicker dropdown-toggle btn btn-secondary btn-sm ${buttonClassName}`}
                dateFormat="yyyy-MM-dd"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                selected={startDate}
                onChange={onDateChanged} />
            </div>
            : null}
          {width > wideModeWidth && selectedEvent === 'user-upload' ? <>
            <div className={'flex-grow-1'}></div>
            <div className={colClassName}>
              <DeleteMatchButton
                matchIds={Array.from(selected)}
                deleteMultiple={true}
              />
            </div></>
            : null}
        </div>
      </div>
      <hr style={{ width: "100%" }}></hr>
      {fetching ?
        <div className="py-3" style={{ textAlign: "center" }}><span><Spinner animation="border" /><span className="loading-label">Loading...</span></span></div> :
        <>
          {Object.keys(matches).length > 0 ? null :
            <>
              <div className="match-table-disabled">No matches found.</div>
            </>
          }
          <table className="table">
            <tbody>
              {matches.slice(0, maxResults).map((match, index) => {
                const new_date = index !== 0 && selectedTeam === "Any" && matches[index].date !== matches[index - 1].date;
                const match_id = match.match_id;
                const stars_class = selectedTeam === "Any" && match.stars > 0 ? `match-table-stars-${match.stars}` : '';
                const dateRow = new_date ?
                  <tr key={`tr_${matches[index].date}`}>
                    <td colSpan={11} style={{ paddingTop: 40, marginLeft: 0, paddingLeft: 6 }}>{match.date}</td>
                  </tr> : null;

                return width > wideModeWidth ?
                  <React.Fragment key={`tr_wrapper_${match_id}`}>
                    {dateRow}
                    <tr key={`tr_${match_id}`}>
                      {selectedEvent === 'user-upload' &&
                        <td className={`match-table-item match-table-event align-middle ${stars_class}`} style={{ width: '40px' }}>
                          <Form.Check
                            type="checkbox"
                            checked={selected.has(match_id)}
                            onChange={(e) => handleSelectRow(match_id, e.target.checked)}
                            className={styles.largeCheckbox}
                          />
                        </td>
                      }
                      <td className={`match-table-item match-table-date align-middle ${stars_class}`}>{match.date}</td>
                      <td className={`match-table-item match-table-time align-middle ${stars_class}`}>{match.time.split(":")[0]}:{match.time.split(":")[1]}</td>
                      <td className={`match-table-item match-table-team align-middle ${stars_class}`}>
                        <a href={selectedMap === "Any" ? `/filter?team=${match.teamA_key}&mapname=${match.maps[0]}` : `/filter?team=${match.teamA_key}&mapname=${selectedMap}`} className='match-table-team-link'>
                          <TeamIcon size={20} name={match.teamA_key} flag={match.teamA_flag} />
                          <span className='match-table-team-name-label'>{match.teamA_name}</span>
                          <i className="bi bi-search match-table-hover-icon" style={{ color: '#888', marginLeft: '10px' }} />
                        </a>
                      </td>
                      <td className={`match-table-item match-table-team align-middle ${stars_class}`}>
                        <a href={selectedMap === "Any" ? `/filter?team=${match.teamB_key}` : `/filter?team=${match.teamB_key}&mapname=${selectedMap}`} className='match-table-team-link'>
                          <TeamIcon size={20} name={match.teamB_key} flag={match.teamB_flag} />
                          <span className='match-table-team-name-label'>{match.teamB_name}</span>
                          <i className="bi bi-search match-table-hover-icon" style={{ color: '#888', marginLeft: '10px' }} />
                        </a>
                      </td>
                      <td className={`match-table-item match-table-points align-middle text-center ${stars_class}`}><span className='match-table-score'>{match.pointsA} - {match.pointsB}</span></td>
                      <td className={`match-table-item match-table-replay text-end align-middle ${stars_class}`}>
                        <a
                          href={selectedMap === "Any" ? `/match/${match_id}` : `/match/${match_id}/${selectedMap}`}
                          onClick={(e) => {
                            e.preventDefault();
                            sessionStorage.removeItem('playlist');
                            selectedMap === "Any" ? navigate(`/match/${match_id}`) : navigate(`/match/${match_id}/${selectedMap}`)
                          }}
                          className="btn btn-sm btn-primary"
                          role="button"
                        >
                          <i className="bi bi-play-fill" style={{ marginRight: '7px' }} />Replay
                        </a>
                      </td>
                      <td className={`match-table-item text-end align-middle ${stars_class}`}>
                        <Dropdown
                          onToggle={() => handleDropdownToggle(`analyse-${match_id}`)}
                          show={activeDropdown === `analyse-${match_id}`}
                          key={`analyse-dropdown-${match.match_id}`}
                        >
                          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-sm btn-warning btn-analyse" size="sm">
                            <i className="bi bi-cup-hot" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="maps-dropdown-menu">
                            <Dropdown.Header style={{ color: '#666' }}>
                              <i className="bi bi-cup-hot" style={{ marginRight: '10px' }} />Analyse
                            </Dropdown.Header>
                            {isLocked(match.match_id, subscription) ? (
                              <Dropdown.Item disabled>
                                <i className="bi bi-lock-fill" style={{ marginRight: '8px', fontSize: '14px', position: 'relative', top: '-2px' }} />
                                {subscription !== 'BASIC' ? 'For subscription only' : 'Premium subscription only'}
                              </Dropdown.Item>
                            ) : (
                              [
                                [match.teamA_key, match.teamA_flag, match.teamA_name],
                                [match.teamB_key, match.teamB_flag, match.teamB_name],
                              ].map(([teamKey, teamFlag, teamName]) =>
                                match.maps.map((map) => (
                                  <Dropdown.Item
                                    key={`analyse-${teamKey}-${map}`}
                                    eventKey={`analyse-${teamKey}-${map}`}
                                    style={{ color: '#ccc' }}
                                    onMouseDown={(event) => handleMouseDown(event, match.match_id, teamKey, map)}
                                  >
                                    {loadingAnalyseID === `${match.match_id}.${teamKey}.${map}` ? (
                                      <Spinner animation="border" size="sm" style={{ marginRight: 15, color: '#555' }} />
                                    ) : (
                                      <TeamIcon size={14} name={teamKey} flag={teamFlag} />
                                    )}
                                    {teamName}
                                    <span style={{ color: '#444', paddingLeft: '7px', paddingRight: '5px' }}>/</span>
                                    <span style={{ color: '#666' }}>{map}</span>
                                  </Dropdown.Item>
                                ))
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      {selectedEvent !== "user-upload" ?
                        <td className={`match-table-item text-center align-middle ${stars_class}`}>
                          <ExternalStatsButton matchId={match_id} statsUrl={match.url} />
                        </td> :
                        <td className={`match-table-item text-center align-middle ${stars_class}`}>
                          <DeleteMatchButton matchIds={[match_id]} />
                        </td>
                      }
                      <td className={`match-table-item align-middle match-table-maps ${stars_class}`} style={{ borderBottom: '0px solid black' }}>
                        {match.maps.map(map => (
                          <OverlayTrigger
                            key={`map-overlaytrigger-${match_id}-${map}`}
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-overlaytrigger-${match_id}-${map}`}>
                                {cleanMapName(map)}
                              </Tooltip>
                            }
                          >
                            <a
                              href={`/match/${match_id}/${map}`}
                              onClick={(e) => {
                                e.preventDefault();
                                sessionStorage.removeItem('playlist');
                                navigate(`/match/${match_id}/${map}`)
                              }}
                              role="button"
                            >
                              <div className='match-table-map' key={`map-${match_id}-${map}`}>
                                <MapIcon key={`map-${match_id}-${map}`} name={map} size={20} margin={0} />
                              </div>
                            </a>
                          </OverlayTrigger>
                        ))}
                      </td>
                    </tr>
                  </React.Fragment> :
                  <tr key={`tr_${match_id}`}>
                    <td className={`match-table-item align-middle w-100 ${stars_class}`}>
                      <div className='match-table-date-minimal'>{match.date} {match.time.split(":")[0]}:{match.time.split(":")[1]}</div>
                      <div><TeamIcon size={16} name={match.teamA_key} flag={match.teamA_flag} /><span className='match-table-team-name-label'>{match.teamA_name}</span></div>
                      <div><TeamIcon size={16} name={match.teamB_key} flag={match.teamB_flag} /><span className='match-table-team-name-label'>{match.teamB_name}</span></div>
                    </td>
                    <td className={`match-table-item match-table-cs2 align-middle`} style={{ position: 'relative', top: '8px', left: '-10px' }}>{match.cs_version === "cs2" && <div className="match-table-cs2-div">CS2</div>}</td>

                    <td className={`match-table-item align-middle match-table-points-minimal ${stars_class}`}>
                      <div className='match-table-date-minimal'>&nbsp;</div>
                      <div>{match.pointsA}</div>
                      <div>{match.pointsB}</div>
                    </td>
                    <td className={`match-table-item match-table-replay text-end align-middle ${stars_class}`}>
                      <Button style={{ position: "relative", top: 9, height: 40 }} className="btn-sm" onClick={() => selectedMap === "Any" ? navigate(`/match/${match_id}`) : navigate(`/match/${match_id}/${selectedMap}`)}><i className="bi bi-play-fill"></i>Replay</Button>
                    </td>
                  </tr>;
              })}
            </tbody>
          </table>
          {maxResults > Object.keys(matches).length ? null :
            <>
              <div className="container text-center"><Button onClick={() => setMaxResults(maxResults + 100)} variant="link">Show more</Button></div>
              <br />
            </>
          }
        </>
      }
    </div >
  );
}
