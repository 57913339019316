import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';
import styles from './OrgPricingInfoPopup.module.css';

interface OrgPricingInfoPopupProps {
  onClose: () => void;
  show: boolean;
}

const OrgPricingInfoPopup: React.FC<OrgPricingInfoPopupProps> = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose} dialogClassName={styles.modal}>
      <CustomModalHeader hideCloseButton={false} />
      <div className={styles.content}>
        <p>
          Starting <b>1st January 2025</b>, the pricing for CS2Lens org license will increase for both existing and new customers.
        </p>
        <p>
          The new price will be <b>$79/month</b> (+VAT).
        </p>
        <p>
          Since launching the org subscription last year, we’ve added several significant new features like <b>voice comms</b> and <b>POV demo support</b>. We are confident this new pricing remains fair given our ongoing improvements.
        </p>
        <p>
          As a reminder, your org license also covers <b>academy teams</b>, including their own admin account and upload database at no additional cost. <a href="/support">Contact us</a> if you’d like to get your academy team(s) set up.
        </p>
        <p>
          Thank you for being part of CS2Lens. If you have any questions, feel free to reach out.
        </p>
        <p>
          Best regards,<br />
          Juha Kiili / CS2Lens
        </p>
      </div>
    </Modal>
  );
};

export default OrgPricingInfoPopup;