import CustomModalHeader from "./CustomModalHeader";
import { Button, Dropdown, Form, Modal, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styles from "./MatchFilterPopup.module.css";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import TeamPicker from "./TeamPicker";
import MapIcon from "./MapIcon";
import { fetchFromApi } from "../utils/api";
import TeamIcon from "./TeamIcon";
import { Playlist, RoundsMetadata } from "../utils/types";
import { filterEmptyOrLockedMatches } from "../pages/RoundFilter";
import { fetchRoundsOfMatchIds, getPlaylistFromRounds, sortPlaylist } from "../utils/playlist";
import { getHasSubscription, getSubscription, isLocked } from "../utils/premium";

const mapnameOptions: string[] = ['ancient', 'anubis', 'dust2', 'inferno', 'mirage', 'nuke', 'overpass', 'vertigo', 'train'];
const timespanOptions: string[] = ['1 Month', '3 Months', 'All time'];
export interface ApiMatchesData {
  [matchID: string]: MatchData
}

export interface MatchData {
  match_id: string
  teamA_key: string,
  teamB_key: string,
  teamA_name: string,
  teamB_name: string,
  teamA_flag: string,
  teamB_flag: string,
  date: string,
  time: string,
  maps: string[]
  pointsA: number,
  pointsB: number,
  timestamp: number,
  stars: number,
  url: string,
  cs_version?: string | undefined;
}

const getAfterParam = (timespan: string) => {
  switch (timespan) {
    case '1 Month':
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      return `&after=${oneMonthAgo.toISOString().split('T')[0]}`;
    case '3 Months':
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
      return `&after=${threeMonthsAgo.toISOString().split('T')[0]}`;
    case 'All time':
      return '';
    default:
      console.error('Invalid timespan');
      console.log(timespan);
      return '';
  }
};

function daysAgo(dateString: string): number {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();

  // Convert milliseconds to days (1 day = 24 hours = 1440 minutes = 86400 seconds = 86400000 milliseconds)
  return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
}


export default function MatchFilterPopup({
  show,
  initialTeamKey,
  initialMapname,
  initialMatchIds,
  handleAnalyse,
  handleCloseModal,
}: {
  show: boolean,
  initialTeamKey: string | undefined,
  initialMapname: string | undefined,
  initialMatchIds: string[],
  handleAnalyse: (playlist: Playlist) => void,
  handleCloseModal: () => void
}): JSX.Element {
  const [firstFetch, setFirstFetch] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [matches, setMatches] = useState<MatchData[] | undefined>(undefined);
  const [selectedMatches, setSelectedMatches] = useState<string[]>(initialMatchIds);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(initialTeamKey);
  const [selectedMapname, setSelectedMapname] = useState(initialMapname || 'ancient');
  const [selectedTimespan, setSelectedTimespan] = useState('1 Month');
  const [submitting, setSubmitting] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  useEffect(() => {
    if (!fetching || selectedTeam === undefined || selectedMapname === undefined || selectedTimespan === undefined)
      return;

    setMatches(undefined);
    const afterParam = getAfterParam(selectedTimespan);
    const url = `/matches?teams=${selectedTeam}&maps=${selectedMapname.toLowerCase()}${afterParam}`;
    fetchFromApi(url)
      .then((response) => response.json())
      .then((data: ApiMatchesData) => {
        const sortedMatchesArray = Object.keys(data)
          .map((key) => { data[key].match_id = key; return data[key]; })
          .filter((match) => match.cs_version === 'cs2')
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            if (a.stars < b.stars) return 1;
            if (a.stars > b.stars) return -1;
            return b.timestamp - a.timestamp;
          });
        setMatches(sortedMatchesArray);
        if (!firstFetch || initialMatchIds.length === 0) {
          setSelectedMatches(sortedMatchesArray?.filter((match) => !isLocked(match.match_id, subscription)).map((match) => match.match_id) || []);
        }
        setFetching(false);
        setFirstFetch(false);
      })
      .catch((err) => {
        console.error(err.message);
        setMatches(undefined);
        setSelectedMatches([]);
        setFetching(false);
        setFirstFetch(false);
      });
  }, [fetching, selectedMapname, selectedTeam, selectedTimespan]);

  const handleSubmit = async (event: React.FormEvent) => {
    setSubmitting(true);
    const rounds = await fetchRoundsOfMatchIds(selectedMatches, selectedMapname);
    setSubmitting(false);
    const playlist = getPlaylistFromRounds(rounds);
    playlist.team_key = selectedTeam;
    playlist.mapname = selectedMapname;
    handleAnalyse(playlist);
  };

  const handleDropdownToggle = (dropdown: string) => {
    setActiveDropdown((old) => old === dropdown ? '' : dropdown);
  };

  const handleCheckboxChange = (matchId: string, checked: boolean) => {
    // Check if the user is premium
    if (isLocked(matchId, subscription)) {
      return;
    }
    if (checked) {
      setSelectedMatches((old) => [...old, matchId]);
    } else {
      setSelectedMatches((old) => old.filter((id) => id !== matchId));
    }
  }

  const handleSelecteAllCheckboxChange = (checked: boolean) => {
    if (checked) {
      setSelectedMatches(matches?.filter((match) => !isLocked(match.match_id, subscription)).map((match) => match.match_id) || []);
    } else {
      setSelectedMatches([]);
    }
  }

  const onSearchFilterChanged = () => {
    setFetching(true);
  }

  const onMapChanged = (newMap: string) => {
    setSelectedMapname(newMap);
    onSearchFilterChanged();
  }

  const onTimespanChanged = (newTimespan: string) => {
    setSelectedTimespan(newTimespan);
    onSearchFilterChanged();
  }

  return (
    <Modal show={show} onHide={handleCloseModal} dialogClassName={styles.modal}>
      <CustomModalHeader />
      <div style={{ margin: '25px', display: 'flex' }}>
        <TeamPicker
          isOpen={activeDropdown === 'team'}
          selectedTeam={selectedTeam}
          wideMode={true}
          canSelectAny={false}
          label="Team"
          btnClassName={styles.teamFilterButton}
          btnClassNameWide={styles.teamFilterButton}
          onToggle={(isOpen: boolean) => handleDropdownToggle('team')}
          onSelect={(team: string) => {
            sessionStorage.setItem('round-filter-team', team);
            setSelectedTeam(team);
            onSearchFilterChanged();
          }}
        />
        <Dropdown
          onSelect={(eventKey: any, event: Object) => onMapChanged(eventKey)}
          onToggle={() => handleDropdownToggle('maps')}
          show={activeDropdown === 'maps'}
          style={{ marginLeft: '10px' }}
          className="maps-dropdown"
          key="maps-dropdown"
        >
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" className={styles.mapFilterButton}>
            <><MapIcon name={selectedMapname} size={14} />{selectedMapname}</>
          </Dropdown.Toggle>
          <Dropdown.Menu className='maps-dropdown-menu'>
            {mapnameOptions.map((mapName) => (
              <Dropdown.Item key={`map_${mapName}`} eventKey={mapName}><MapIcon name={mapName} size={14} />{mapName}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          onSelect={(eventKey: any, event: Object) => onTimespanChanged(eventKey)}
          onToggle={() => handleDropdownToggle('timespan')}
          show={activeDropdown === 'timespan'}
          style={{ marginLeft: '10px' }}
          className="maps-dropdown"
          key="timespan-dropdown"
        >
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" className={styles.mapFilterButton}>
            {selectedTimespan}
          </Dropdown.Toggle>
          <Dropdown.Menu className='maps-dropdown-menu'>
            {timespanOptions.map((timespan) => (
              <Dropdown.Item key={`timespan_${timespan}`} eventKey={timespan}><MapIcon name={timespan} size={14} />{timespan}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ marginLeft: '25px', marginBottom: '25px' }}>
        <div className={styles.tableWrapper}>
          {selectedTeam === undefined && <span className={styles.loadingLabel} style={{ left: '0px' }}>Please select a team.</span>}
          {fetching && selectedTeam !== undefined && <span><Spinner size="sm" animation="border" style={{ color: '#666' }} /><span className={styles.loadingLabel}>Loading matches...</span></span>}
          {!fetching && matches && matches.length === 0 && <span style={{ marginLeft: '0px', color: '#666' }}>No matches found.</span>}
          {matches && matches.length > 0 &&
            <table className={styles.table}>
              <tbody>
                {matches && matches.map((match) => (
                  <tr key={match.match_id} style={{ opacity: selectedMatches.includes(match.match_id) ? 1.0 : 0.66 }}>
                    <td className={styles.checkbox} style={{ width: '30px' }}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedMatches.includes(match.match_id)}
                        onChange={(e) => handleCheckboxChange(match.match_id, e.target.checked)}
                      />
                    </td>
                    <td className={styles.matchDate}>
                      <span style={{ color: '#888', marginRight: '5px' }}>{daysAgo(match.date)}</span> days ago
                    </td>
                    <td style={{ width: '100%' }}>
                      <TeamIcon size={14} name={match.teamA_key !== selectedTeam ? match.teamA_key : match.teamB_key} />
                      {match.teamA_key !== selectedTeam ? match.teamA_name : match.teamB_name}
                    </td>
                    <td>
                      {isLocked(match.match_id, subscription) && <span className={`${styles.premiumLink} ${styles.inlineFlexCenter}`}><i className="bi bi-lock-fill" style={{ marginRight: '5px', fontSize: '12px' }} />PREMIUM</span>}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className={styles.footer}>
                    <Form.Check
                      type="checkbox"
                      checked={selectedMatches.length > 0 && selectedMatches.length === matches?.filter((match) => !isLocked(match.match_id, subscription)).length}
                      onChange={(e) => handleSelecteAllCheckboxChange(e.target.checked)}
                    />
                  </td>
                  <td className={styles.footer} colSpan={5} style={{ width: '100%' }}>
                    {selectedMatches.length > 0 && selectedMatches.length === matches?.filter((match) => !isLocked(match.match_id, subscription)).length ? 'Deselect all' : 'Select all'}
                  </td>
                </tr>
              </tfoot>
            </table>
          }
        </div>
      </div>
      <div style={{ marginLeft: '25px', marginBottom: '25px' }}>
        <Button
          variant="warning"
          disabled={selectedMatches.length === 0 || submitting}
          onClick={handleSubmit}
        >
          {!submitting ? <i className="bi bi-cup-hot" style={{ marginRight: '10px' }} /> : <Spinner size="sm" animation="border" style={{ color: '#666', marginRight: '10px' }} />}Analyse
        </Button>
      </div>
    </Modal>
  )
}