export const mapInfo = {
  de_anubis:  {x: -2788, y: 3290, scale: 5.2, resolution: 275.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.05, zoomWide: 1.08, offset: [0, 0.3]},
  de_ancient: {x: -2953, y: 2164, scale: 5.0, resolution: 256.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.15, zoomWide: 1.18, offset: [0, 1]},
  de_cache:   {x: -2000, y: 3250, scale: 5.5, resolution: 256.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, zoomWide: 1.0, offset: [0, 0]},
  de_dust2:   {x: -2476, y: 3239, scale: 4.4, resolution: 199.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, zoomWide: 1.0, offset: [0, 0]},
  de_inferno: {x: -2087, y: 3870, scale: 4.9, resolution: 245.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.1, zoomWide: 1.1, offset: [-1, 1]},
  de_mirage:  {x: -3230, y: 1713, scale: 5.0, resolution: 256.0, minZ: -380, maxZ: -300, splitZ: -320, zoom: 1.25, zoomWide: 1.35, offset: [-1.5, 0]},
  de_nuke:    {x: -3453, y: 2887, scale: 7.0, resolution: 504.0, minZ: -500, maxZ: -416, splitZ: -495, zoom: 1.52, zoomWide: 1.85, offset: [-2.5, -2]},
  de_overpass:{x: -4831, y: 1781, scale: 5.2, resolution: 277.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, zoomWide: 1.0, offset: [-2, 0]},
  de_train:   {x: -2308, y: 2078, scale: 4.08, resolution: 170.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, zoomWide: 1.07, offset: [-3, 1]},
  de_vertigo: {x: -3168, y: 1762, scale: 4.05, resolution: 165.0, minZ: 11490, maxZ: 11750, splitZ: 11700, zoom: 1.3, zoomWide: 1.4, offset: [5, 1]},
}

export const mapInfoAnalyse = {
  de_anubis:  {heatMapBandwidth: 2.2, x: -2788, y: 3290, scale: 5.2, resolution: 275.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.05, offset: [0, 0]},
  de_ancient: {heatMapBandwidth: 2.6, x: -2953, y: 2164, scale: 5.0, resolution: 256.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.15, offset: [0, 1]},
  de_cache:   {heatMapBandwidth: 2.4, x: -2000, y: 3250, scale: 5.5, resolution: 256.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, offset: [0, 0]},
  de_dust2:   {heatMapBandwidth: 2.4, x: -2476, y: 3239, scale: 4.4, resolution: 199.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, offset: [0, 0]},
  de_inferno: {heatMapBandwidth: 2.1, x: -2087, y: 3870, scale: 4.9, resolution: 245.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.1, offset: [-1, 1]},
  de_mirage:  {heatMapBandwidth: 2.2, x: -3230, y: 1713, scale: 5.0, resolution: 256.0, minZ: -380, maxZ: -300, splitZ: -320, zoom: 1.4, offset: [-1.5, 0]},
  de_nuke:    {heatMapBandwidth: 1.4, x: -3453, y: 2887, scale: 7.0, resolution: 504.0, minZ: -500, maxZ: -416, splitZ: -495, zoom: 1.82, offset: [-1.5, -3]},
  de_overpass:{heatMapBandwidth: 2.2, x: -4831, y: 1781, scale: 5.2, resolution: 277.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.0, offset: [-2, 0]},
  de_train:   {heatMapBandwidth: 2.2, x: -2308, y: 2078, scale: 4.08, resolution: 170.0, minZ: -20000, maxZ: -10000, splitZ: -10000, zoom: 1.08, offset: [-3, 0]},
  de_vertigo: {heatMapBandwidth: 2.2, x: -3168, y: 1762, scale: 4.05, resolution: 165.0, minZ: 11490, maxZ: 11750, splitZ: 11700,  zoom: 1.4, offset: [4, 1]},
}